.chat_sidebar_wrapper {
  width: 340px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-right: 1px solid rgba(145, 158, 171, 0.24);
  overflow-y: auto;
  overflow-x: hidden;
}
.chat_sidebar_wrapper.hide {
  width: 96px;
}
.chat_sidebar_wrapper.hide .arrow_wrapper {
  justify-content: center;
}
.chat_sidebar_wrapper.hide .arrow_wrapper button {
  margin-left: 0;
}
.chat_sidebar_header {
  height: 140px;
  border-right: 1px solid rgba(145, 158, 171, 0.24);
}
.chat_sidebar_content_box {
  position: relative;
  height: calc(100vh - 196px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.chat_sidebar_content_box.scrolling {
  box-shadow: inset 0 14px 10px -10px rgb(60 64 67 / 28%) !important;
}
.chat_sidebar_content_box.scrolling::before {
  z-index: 1;
  content: '';
  width: 100%;
  height: 14px;
  position: absolute;
  box-shadow: inset 0 14px 10px -10px rgb(60 64 67 / 28%) !important;
}
.chat_sidebar_content_box::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.chat_sidebar_content_box::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
  background-color: #dee1e6 !important;
}
.chat_sidebar_content_box::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.chat_sidebar_chat_list {
  padding-top: 0 !important;
}
.chat_sidebar_card {
  display: flex;
  align-items: flex-start !important;
  width: 100%;
}
.chat_unread_msg_badge {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6200ee;
  color: #fff;
  flex: 0 0 auto;
}
.more-chat-msg-output {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000073;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}
.name-wrapper-input {
  white-space: nowrap;
}
.multiple-wrapper {
  max-width: 275px !important;
  width: 100%;
  gap: 4px;
}
.chat_interaction_content {
  height: calc(100vh - 153px) !important;
  width: 100% !important;
  padding: 1rem 10% 0 !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
}
.post_interaction_content,
.event_interaction_content {
  height: calc(100vh - 153px) !important;
  width: 100% !important;
  padding: 0px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
}

.chat_interaction_content::-webkit-scrollbar,
.post_interaction_content::-webkit-scrollbar,
.event_interaction_content::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.chat_interaction_content::-webkit-scrollbar-thumb,
.post_interaction_content::-webkit-scrollbar-thumb,
.event_interaction_content::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
  background-color: #6e3fc6 !important;
}

.chat_interaction_content::-webkit-scrollbar-track,
.post_interaction_content::-webkit-scrollbar-track,
.event_interaction_content::-webkit-scrollbar-track {
  background-color: rgb(241, 243, 244) !important;
}

.chat_log_wrapper_right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  /* margin-bottom: 1rem; */
  margin: 0 4px 1rem 0;
}
.chat_log_timestamp {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #b4b4b4;
  margin-bottom: 12px;
  text-transform: lowercase;
}
.chat_log_content_right {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  word-break: break-word;
  max-width: 40%;
  padding: 18px 24px;
  color: #6200ee;
  background: #fff;
  border-radius: 16px 16px 5px 16px;
  gap: 8px;
}

.multiple-wrapper {
  cursor: pointer;
  /* z-index: 2; */
}

.multiple-wrapper .more-image {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000091;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #ffffff;
  z-index: 9;
  pointer-events: none;
}

.multiple-wrapper img {
  border-radius: 6px;
  height: 92px;
  object-fit: cover;
}

.chat_log_content_right video,
.chat_log_content_left video {
  width: 100%;
  height: 92px;
  object-fit: cover;
}

.chat_log_content_right a {
  color: #6200ee;
}

.chat_log_content_left a {
  color: #ffffff;
}

.chat_log_content_right {
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  max-width: 40%;
  padding: 18px 24px;
  color: #2d3748;
  background: #efe5fd;
  border-radius: 16px 16px 5px 16px;
}

.chat_log_wrapper_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 1rem 4px;
}
.chat_log_wrapper_left .file_preview_file_label {
  background: transparent;
  color: #2d3748;
}
.chat_log_content_left {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  word-break: break-word;
  max-width: 40%;
  padding: 18px 24px;
  color: #2d3748;
  background: #fff;
  border-radius: 16px 16px 16px 5px;
  gap: 8px;
}

/* Chat Input Style */

.chat_input_wrapper {
  position: relative;
  /* height: 100px; */
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 10%;
  z-index: 9;
}
.chat_emoji_input_wrapper {
  position: absolute;
  left: 1%;
  bottom: 102%;
  z-index: 10000;
  max-height: 200px !important;
}
.chat_emoji_input_btn {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  background-color: transparent;
  left: 12px;
  cursor: pointer;
  min-width: unset !important;
  margin-right: 0;
  color: rgb(13, 14, 18, 0.5) !important;
}
.chat_emoji_input_btn.active {
  color: #3b37da !important;
}

/* Group Chat Style */

.chat_header_submenu_btn {
  width: 6px;
  display: flex;
  align-items: center;
  margin-left: 8px !important;
}
.group_chat_contact_search_box {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #6e3fc6 !important ;
  padding: 0 1rem !important;
}
.group_chat_contact_search_box .MuiInput-root {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  text-shadow: 0px 1px 3px #000 !important;
}
.group_chat_contact_search_box .MuiInput-root::before {
  border-bottom: 0 !important;
}
.group_chat_contact_search_box .MuiInput-root::after {
  border-bottom: 0 !important;
}
.group_chat_selected_contact_box {
  width: 100%;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  transition: all 0.3s ease-in;
  padding: 0;
}
.group_chat_selected_contact_box.data {
  height: 80px;
  padding: 1rem;
  border-bottom: 1px solid rgba(202, 202, 202, 0.26);
}
.selected_contact_avatar_box {
  min-width: 60px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.close_delete_btn {
  position: absolute !important;
  top: 0;
  right: 0px;
  min-width: unset !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  background-color: #6e3fc6 !important;
  border: 2px solid #fff !important;
  z-index: 100;
}
.group_chat_contact_box {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.group_chat_contact_box.scrolling {
  box-shadow: inset 0 14px 10px -10px rgb(60 64 67 / 28%) !important;
}
.group_chat_contact_box.scrolling::before {
  z-index: 1;
  content: '';
  width: 100%;
  height: 14px;
  position: absolute;
  box-shadow: inset 0 14px 10px -10px rgb(60 64 67 / 28%) !important;
}
.group_chat_contact_box::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.group_chat_contact_box::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
  background-color: rgb(60 64 67 / 28%) !important;
}
.group_chat_contact_box::-webkit-scrollbar-track {
  background-color: rgb(241, 243, 244) !important;
}
.group_chat_contact_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group_chat_check_label {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0d0e11;
  cursor: pointer;
}
.group_chat_check_input:checked + .group_chat_check_label {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6e3fc6 !important;
  border: 0;
  color: #fff !important;
}

.group_chat_img_upload_label {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.group_chat_img_upload_label.notEmpty {
  position: absolute !important;
  top: 70%;
  left: 80%;
  padding: 0 !important;
  min-width: unset !important;
  width: fit-content !important;
  transform: translateY(-50%);
}
.group_chat_img_upload_preview {
  height: 100%;
  width: 100%;
  object-fit: cover !important;
  object-position: center !important;
  border-radius: inherit !important;
}
.chat_popup_wrapper .MuiPaper-root.MuiDialog-paper {
  width: 450px !important;
  min-height: 500px !important;
  max-height: 650px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.chat_config_popup_header {
  position: relative !important;
  height: 120px;
  width: 100%;
  padding: 0 !important;
  display: flex;
  align-items: center;
  background-color: #6e3fc6;
  transition: all 0.3s ease-in-out !important;
}
/* .chat_config_popup_header.contentScrolling {
  position: relative !important;
  height: 60px;
  width: 100%;
  padding: 0 !important;
  display: flex;
  align-items: center;
  background-color: #6e3fc6;
} */
.chat_config_popup_close_btn {
  position: absolute !important;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.chat_popup_avatar_wrapper {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -75%);
  transition: all 0.3s ease-in-out !important;
  z-index: 100;
}
/* .chat_popup_avatar_wrapper.contentScrolling {
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
} */
.chat_config_popup_chatName_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.group_chat_participant_list li:not(:last-child) {
  border-bottom: 1px solid #e6e8f1ad;
}
/* Chat File Preview Style */

.files_preview_wrapper {
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  width: 80%;
  height: 330px;
  background-color: #fff;
  box-shadow: 1px 4px 8px rgb(86 82 84 / 56%);
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  z-index: -1;
}
.files_preview_wrapper_replay {
  min-height: auto;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  padding: 8px;
  width: 96px;
  flex: 0 0 96px;
}
.files_preview_wrapper_replay .file_preview_container img {
  object-fit: cover;
}
.files_preview_wrapper_replay
  .file_preview_container
  .file_preview_file_container,
.files_preview_wrapper_replay .file_preview_container {
  height: 80px;
  border-radius: 12px;
  /* overflow: hidden; */
}
/* .file_preview_container {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid rgba(30, 38, 57, 0.08);
  border-radius: 12px;
} */
.file_preview_container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  position: relative;
  border: 1px solid rgba(30, 38, 57, 0.08);
  border-radius: 12px;
}
.file_preview_file_container {
  height: 150px;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.file_preview_file_container img {
  object-fit: contain;
  width: 100%;
}
.file_preview_file_container video {
  height: 100%;
  width: 100%;
}
.map_preview_file_container {
  height: 250px;
  width: 344px;
  padding-bottom: 1rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.map_onclick_file_label {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  background: #e6e8f1;
  margin: 0;
  padding: 10px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  cursor: pointer;
  color: #3b37da;
}

.file_preview_file_label {
  font-size: 14px;
  font-weight: 400;
  /* background: #e6e8f1; */
  margin: 0;
  padding: 10px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: #6e3fc6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}
.file_preview_remove_btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  top: -8px;
  right: -8px;
  background: #ffffff;
  height: 20px;
  width: 20px;
  padding: 5px;
  border-radius: 50%;
  z-index: 9;
}
.file_preview_remove_btn:hover {
  background: #0d0e11;
  color: #e6e8f1 !important;
}
.img_upload_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 1px solid #b4b4b4;
  border-radius: 50%;
  box-shadow: 1px 4px 8px rgb(86 82 84 / 56%);
  margin: 0 auto;
  /* overflow: hidden; */
}
.chat_module_placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 60px);
}
.chat_window_respo_back_btn {
  display: none !important;
}

.reaction_hover_wrapper {
  position: relative;
}
.reaction_hover_wrapper::before {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  display: inline-block;
  transform: translateX(-50%) rotate(224deg);
  background-color: #efe5fd;
  z-index: -1;
}
.reaction_doer_details::-webkit-scrollbar {
  background-color: #6e3fc6 !important;
  width: 2px !important;
  height: 5px;
}
.chat_reply_normal_msg .reply_text_content {
  position: relative;
}
.chat_reply_normal_msg .reply_text_content span {
  display: block;
}
.chat_log_content_right span,
.chat_log_content_left span {
  /* white-space: nowrap !important; */
  max-width: 250px;
  /* padding-left: 20px; */
  border-radius: 4px !important;
}

.swiper {
  width: 100%;
}
.video-image-carousel .chatlog_img_container .img-fluid,
.video-image-carousel video {
  height: calc(100vh - 20px);
  width: 100%;
  padding-top: 40px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  object-fit: contain;
}
.video-image-carousel video {
  height: unset;
  max-height: calc(100vh - 20px);
  width: 100%;
}
.video-image-carousel .close-icon {
  position: absolute;
  top: 85px;
  right: 25px;
  color: #ffffff;
  cursor: pointer;
  z-index: 99999;
}
.play-icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  color: #ffffff;
  font-size: 2.4rem !important;
  cursor: pointer;
  z-index: 2;
}
.video-image-carousel .chatlog_img_container {
  text-align: center;
}
.video-image-carousel .swiper-button-prev {
  left: 50px;
  color: white;
  top: calc(50% + 40px);
}
.video-image-carousel .swiper-button-next {
  right: 50px;
  color: white;
  top: calc(50% + 40px);
}
.video-image-carousel .swiper-button-prev,
.video-image-carousel .swiper-button-next {
  height: 40px;
  width: 40px;
  background-color: #ffffff51;
  border-radius: 100%;
}
.video-image-carousel .swiper-button-prev:after,
.video-image-carousel .swiper-button-next:after {
  font-size: 18px;
  font-weight: bold;
}
.video-image-carousel {
  position: fixed;
  top: 0;
  left: 340px;
  right: 0;
  bottom: 0;
  background-color: #000000be;
  z-index: 9999;
  justify-content: center;
}
/* .chat_reply_normal_msg .reply_text_content{
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0px;
    background-color: #6e3fc6;

} */

/* Chat Responsive */
.message_info_wraper {
  position: relative;
  width: 80%;
  height: 200px;
  margin: auto;
}
.message_info_wraper .image_wrapper {
  width: 100%;
  height: 100%;
  border: 5px solid #6e3fc6;
  margin: auto;
  border-radius: 16px 16px 0px 16px;
  overflow: hidden;
}
/* .message_info_wraper::after{
  position: absolute;
  top: 0px;
  right: -9px;
  content: "";
  width: 18px;
  height: 10px;
  background-color: #6e3fc6;
  border-bottom-right-radius: 120px;
  border-bottom-left-radius: 115px;
} */
.message_info_wraper .image_wrapper img,
.message_info_wraper .image_wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (min-width: 1400px) {
  .chat_log_content_right,
  .chat_log_content_left {
    max-width: 100%;
    padding: 18px 24px;
  }
}
@media screen and (max-width: 1399.99px) {
  .chat_log_content_right,
  .chat_log_content_left {
    max-width: 100%;
    padding: 16px 22px;
  }
}

@media screen and (max-width: 1199.99px) {
  .chat_log_content_right,
  .chat_log_content_left {
    max-width: 100%;
    padding: 14px 20px;
  }
}
@media screen and (max-width: 991.99px) {
  /* .chat_sidebar_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh - 60px);
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
    background-color: #fff;
  }
  .chat_sidebar_wrapper.show {
    transform: translateX(0%);
  } */
  .chat_interaction_content {
    height: calc(100vh - 269px) !important;
  }
  .chat_interaction_content.upload_files {
    height: calc(100vh - 364px) !important;
  }
  .chat_sidebar_wrapper {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-right: 1px solid rgba(145, 158, 171, 0.24);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .chat_sidebar_wrapper.mobileHide {
    display: none;
  }
  .chat_sidebar_toggleBtn {
    display: none !important;
  }
  .chat_window_respo_back_btn {
    display: flex !important;
  }
  .chat_card.cardShadow {
    box-shadow: unset;
  }
  .chat_left_wrapper {
    padding: 3rem 15% 1rem;
  }
  .chat_right_wrapper {
    display: none;
  }
  .chat_right_wrapper.show {
    display: unset;
  }
  .chat_left_wrapper.hide {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .video-image-carousel {
    left: 0;
  }
  .video-image-carousel .swiper-button-prev,
  .video-image-carousel .swiper-button-next {
    height: 25px;
    width: 25px;
  }
  .video-image-carousel .swiper-button-prev:after,
  .video-image-carousel .swiper-button-next:after {
    font-size: 12px;
  }
  .video-image-carousel .swiper-button-prev {
    left: 8px;
  }
  .video-image-carousel .swiper-button-next {
    right: 8px;
  }
}
@media screen and (max-width: 767.99px) {
  .chat_left_wrapper {
    padding: 3rem 10% 1rem;
  }
  .name-wrapper-input .app_text_20_bold {
    font-size: 16px !important;
  }
  .name-wrapper-input .app_text_18_semibold {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 575.99px) {
  .chat_left_wrapper {
    padding: 3rem 8% 1rem;
  }
  .chat_interaction_content {
    padding: 1rem 4% 0 !important;
  }
  .chat_input_wrapper {
    padding: 1rem 4%;
  }
  .chat_log_content_right,
  .chat_log_content_left {
    padding: 14px;
  }
  .chat_log_content_right span,
  .chat_log_content_left span {
    max-width: 200px;
  }
  .file_preview_file_label {
    max-width: 210px;
  }
}
@media screen and (max-width: 400px) {
  .chat_left_wrapper {
    padding: 3rem 6% 1rem;
  }
}
@media screen and (max-width: 319.99px) {
  .chat_left_wrapper {
    padding: 3rem 6.5% 1rem;
  }
}
