/* Evemt Home style */

.event_wrapper {
  height: auto;
  width: 100%;
}
.event_tab_rounded_box {
  background: #e0ccfc;
  border-radius: 25px;
  width: 600px;
}
.event_tab_rounded_box .MuiTabs-indicator {
  display: none;
}
.event_tab_rounded_box .MuiTabs-flexContainer {
  width: 100% !important;
  padding: 5px !important;
}
.event_tab_rounded_box .MuiButtonBase-root.MuiTab-root {
  height: 40px !important;
  min-height: unset !important;
  line-height: 15px;
  text-transform: lowercase !important;
}
.event_tab_rounded_box .MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #6200ee !important;
  background-color: #fff !important;
  border-radius: 25px !important;
}
.event_content_wrapper {
  width: 100%;
  height: 100%;
}
.event_card {
  display: flex !important;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
  border-radius: 12px !important;
  overflow: hidden !important;
  width: 100% !important;
  align-items: stretch !important;
  padding: 0 !important;
  justify-content: flex-start !important;
  text-align: left !important;
  background-color: #fff !important;
}
.event_card:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
}
.event_card_img_container {
  flex-shrink: 0;
  display: flex;
  width: 100%;
  height: 200px;
}
.event_card_img_container img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}
.event_card_info_container {
  padding: 8px 16px;
  width: 100%;
  display: flex;
}
.event_wrapper .create_event_btn_text {
  display: flex;
}

.sidebar_event_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 60px);
  z-index: 1001;
  background-color: #fff;
}
.sidebar_event_header {
  padding: 22px 12px 22px;
}
.sidebar_event_content {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: -1;
}
.sidebar_event_content.scrolling {
  box-shadow: inset 0 14px 10px -10px rgb(60 64 67 / 28%) !important;
}
.sidebar_event_content.scrolling::before {
  z-index: 1;
  content: '';
  width: 100%;
  height: 14px;
  position: absolute;
  box-shadow: inset 0 14px 10px -10px rgb(60 64 67 / 28%) !important;
}
.sidebar_event_content::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.sidebar_event_content::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
  background-color: #6e3fc6 !important;
}
.sidebar_event_content::-webkit-scrollbar-track {
  background-color: rgb(241, 243, 244) !important;
}
.map_search_box_event {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  width: 356px;
  border-radius: 0 !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%) !important;
}
.map_event_feed_listItem {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.event_feed_card {
  width: 100%;
  /* background: #ffffff; */
  height: 100%;
  border: 1px solid #e7f0ff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.event_img_container {
  width: 120px !important;
  height: 140px !important;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
}
.event_img_container img,
.event_img_container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.event_feed_list {
  padding-top: 0 !important;
}
.event_feed_list li:first-child {
  padding-top: 2px !important;
}
.map_event_detail_sidebar_wrapper {
  width: 100%;
}

.map_sidebar_event_content {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.map_sidebar_event_content::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.map_sidebar_event_content::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
  background-color: #6e3fc6 !important;
}
.map_sidebar_event_content::-webkit-scrollbar-track {
  background-color: rgb(241, 243, 244) !important;
}
.map_event_detail_sidebar_header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
  background-color: #6200ee;
  z-index: 2;
}

.map_event_sidebar_banner_image_container {
  width: 100%;
  height: 240px;
  margin-top: 56px;
}

.map_event_sidebar_banner_image_container .slick-slider,
.map_event_sidebar_banner_image_container .slick-slider .slick-list,
.map_event_sidebar_banner_image_container
  .slick-slider
  .slick-list
  .slick-track,
.map_event_sidebar_banner_image_container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide {
  height: 100% !important;
}

.map_event_sidebar_banner_image_container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div {
  height: 100% !important;
  width: 100%;
}

.map_event_sidebar_banner_image_container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div
  .slide_image_video_wrapper {
  height: 100% !important;
  width: 100%;
}

.map_event_sidebar_banner_image_container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div
  .slide_image_video_wrapper
  img,
.map_event_sidebar_banner_image_container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div
  .slide_image_video_wrapper
  video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* .map_event_sidebar_banner_image_container  {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
} */
.map_event_detail_sidebar_title .MuiAvatar-root.MuiAvatarGroup-avatar {
  width: 32px !important;
  height: 32px !important;
  font-size: 14px;
}

.map_event_detail_sidebar_title {
  padding: 1rem 12px;
}

.event_confirm_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event_confirm_content {
  /* transform: translate(-100px, -60px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.event_post_wrapper
  .MuiAvatarGroup-root
  .MuiAvatar-root.MuiAvatar-colorDefault {
  background-color: #6e3fc6 !important;
}

.create_event_wrapper .img_video_wrapper,
.event_feed_card .new_event_card_image_wrraper {
  width: 100%;
  height: 220px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.create_event_wrapper .img_video_wrapper span.remove_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.create_event_wrapper .img_video_wrapper img,
.create_event_wrapper .img_video_wrapper video,
.event_feed_card .new_event_card_image_wrraper img,
.event_feed_card .new_event_card_image_wrraper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* .create_event_wrapper .color_picker_input_field{
    width: 100%;
    padding: 16.5px 14px;
    border: 1px solid #0000003b;
    border-radius: 5px;
    color: #bdbdbd;
    font-size: 16px;
} */
/* .create_event_wrapper .color_picker_input_field input[type="color"]{
    width: 100%;
    height: 20px !important;
    border: none;
    cursor: pointer;
} */
.ColorPicker-MuiInputBase-root .hiddeninput {
  display: none !important;
}
.color_picker_input_field .makeStyles-root-1 {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  border-radius: 5px !important;
}
.color_picker_input_field .makeStyles-root-1 .ColorPicker-MuiButton-root {
  margin-right: 10px !important;
}

/* .create_event_wrapper .color_picker_input_field input[type="text"]:focus{
     outline: 2px solid #6200ee;
     border:1px solid transparent;
} */
/* .create_event_wrapper .color_picker_input_field input[type="color"]{
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 30px;
  border: none;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
  background-color: unset !important;
  padding: 3px;
} */

/* .create_event_wrapper .color_picker_input_field input[type="color"]:focus ~ .create_event_wrapper .color_picker_input_field input[type="text"]{
  outline: 2px solid #6200ee;
  border:1px solid transparent;
} */

@media screen and (min-width: 1400px) {
}
@media screen and (max-width: 1399.99px) {
}
@media screen and (max-width: 1199.99px) {
  .event_tab_rounded_box {
    background: #e0ccfc;
    border-radius: 25px;
    width: 450px !important;
  }
  .event_tab_rounded_box .MuiTabs-flexContainer {
    width: 450px !important;
    padding: 4px !important;
  }
  .event_tab_rounded_box .MuiButtonBase-root.MuiTab-root {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 991.99px) {
  .event_tab_rounded_box {
    background: #e0ccfc;
    border-radius: 25px;
    width: 480px !important;
  }
  .event_tab_rounded_box .MuiTabs-flexContainer {
    width: 480px !important;
    padding: 4px !important;
  }
  .event_tab_rounded_box .MuiButtonBase-root.MuiTab-root {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 767px) {
  .event_feed_card {
    flex-direction: column;
    align-items: start;
  }
}

@media screen and (max-width: 767.99px) {
  .event_tab_rounded_box {
    background: #e0ccfc;
    border-radius: 25px;
    width: 100% !important;
    margin-right: 1rem;
  }
  .event_tab_rounded_box .MuiTabs-flexContainer {
    width: 100% !important;
    padding: 4px !important;
  }
  .event_tab_rounded_box .MuiButtonBase-root.MuiTab-root {
    font-weight: 600 !important;
    font-size: 12px !important;
  }
  .event_wrapper .create_event_btn {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: unset !important;
    padding: 0 !important;
    flex-shrink: 0 !important;
  }
  .event_wrapper .create_event_btn .MuiButton-startIcon {
    margin: 0;
  }
  .event_wrapper .create_event_btn_text {
    display: none;
  }
}
@media screen and (max-width: 575.99px) {
}
@media screen and (max-width: 450px) {
  .event_tab_rounded_box {
    background: #e0ccfc;
    border-radius: 25px;
    width: 100% !important;
  }
  .event_tab_rounded_box .MuiTabs-flexContainer {
    width: 100% !important;
    padding: 4px !important;
  }
  .event_tab_rounded_box .MuiButtonBase-root.MuiTab-root {
    font-weight: 600 !important;
    font-size: 12px !important;
  }
}
@media screen and (max-width: 400px) {
  .event_tab_rounded_box button {
    min-width: unset;
    padding: 10px 12px;
  }
}
@media screen and (max-width: 319.99px) {
}
