.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #6200ee !important;
}
.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
  color: #6200ee !important;
}

.otp_input_stack .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
  background-color: #f2e9ff !important;
}
.otp_input_stack .MuiInputBase-input.MuiOutlinedInput-input {
  text-align: center !important;
  font-size: 24px !important;
  color: #6200ee !important;
}
.react-tel-input .form-control {
  max-height: 56px !important;
}
.react-tel-input .form-control:focus {
  border-color: #6200ee !important;
  box-shadow: 0 0 0 1px #6200ee !important;
}
.form_mui_checkbox .MuiFormControlLabel-root {
  margin-right: 0 !important;
}
.MuiButtonBase-root.MuiCheckbox-root {
  color: #97a8be !important;
}
.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #6200ee !important;
}
.MuiButtonBase-root.MuiRadio-root.Mui-checked {
  color: #6200ee !important;
}
.MuiButtonBase-root.MuiRadio-root {
  color: #97a8be !important;
}

.MuiPaper-root.MuiDialog-paper {
  border-radius: 10px !important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
  color: #6200ee !important;
}
.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #c8a2ff !important ;
}
.reaction_hover_popup .MuiPaper-root.MuiPopover-paper {
  box-shadow: unset !important;
}
.MuiPaper-root.MuiPopover-paper {
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1), 0px 1px 16px rgba(0, 0, 0, 0.18) !important;
  border-radius: 8px !important;
}

.navbar_config_container .MuiButtonBase-root.MuiButton-root:hover {
  background-color: transparent !important;
}

.tab_rounded_box .MuiTabs-indicator {
  display: none;
}
.tab_rounded_box .MuiTabs-flexContainer {
  width: 100% !important;
  padding: 5px !important;
}
.tab_rounded_box .MuiButtonBase-root.MuiTab-root {
  height: 40px !important;
  min-height: unset !important;
  font-family: 'Google Sans', Roboto, Arial, sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 15px;
}
.tab_rounded_box .MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #6200ee !important;
  background-color: #fff !important;
  border-radius: 25px !important;
}
.list_item_icon_modify {
  min-width: unset !important;
}
.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select {
  line-height: 1 !important;
}
/* Mui Typography customization */

.MuiInputBase-input.MuiOutlinedInput-input {
  font-family: 'Google Sans', Roboto, Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  letter-spacing: 0.875px !important;
}
.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 14px !important;
}
.profile_banner
  .profile_image_wrapper_avatar
  .app_user_avatar
  .userImgPlaceholder {
  border: 3px solid #fff;
}
/* Responsive Topnav Menu Customization */

.responsiveMenu_wrapper .MuiPaper-root.MuiAccordion-root {
  box-shadow: none !important;
}

.social_postcard .MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

.pac-container {
  z-index: 10000 !important;
}
