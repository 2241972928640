.phone_input_box {
  width: 100% !important;
  display: flex !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px !important;
}
.phone_input_box:focus-within {
  border: 1px solid #1976d2 !important;
}
.chat_search_write_text {
  display: flex !important;
  align-items: center !important;
  margin-top: 16px !important;
}
/* .chat_write_text{
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  border: 1px solid rgb(243,245,249) !important;
  margin: 0px auto !important;
  z-index: 1 !important;
  background: rgb(255,255,255) !important;
  min-height: 60px !important;
  border-radius: 45px !important;
  box-shadow: rgb(86 82 84 / 56%) 1px 1px 8px !important;
} */
