.navbar_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #6e3fc6;
  padding: 8px 40px;
  z-index: 1000;
  display: flex;
}

.navbar_container {
  position: relative;
  display: flex;
  width: 100%;
}

.logo_container,
.nav_menu_center,
.nav_menu_right {
  display: flex;
  align-items: center;
}

.nav_left {
  display: flex;
  flex: 0;
}

.logo_link {
  height: 100%;
  display: flex;
  align-items: center;
}

.responsive_btn_container {
  display: none;
}

.nav_menu {
  flex: 1;
  display: flex;
}

.nav_menu_center {
  flex: 1;
  display: flex;
  justify-content: center;
}

:where(.nav_menu_center, .nav_menu_right) > ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.nav_menu_center > ul > li > a {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 100%;
}

.nav_menu_center > ul > li > a.active::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  background: #fff;
  height: 2px;
  width: 24px;
  transform: translateX(-50%);
}

.nav_menu_right > ul > li > a {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 18px;
  height: 100%;
}

.main_nav_config_btn {
  height: 100%;
  min-width: unset !important;
  padding: 0 12px !important;
}

.switch_account_popover_content {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  min-width: 240px;
}

.switch_account_popover_content .MuiListItemButton-root {
  height: 40px !important;
}

.switch_account_popover_content .MuiListItemButton-root:hover {
  background-color: #efe5fd !important;
}

.logout_popover_content {
  padding: 20px;
  min-width: 200px;
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.icon-chat-button_badge {
  margin-left: 10px;
  position: absolute;
  top: -5px;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 60%;
}

.notification_popover_content {
  padding: 20px;
  min-width: 200px;
  width: 350px;
  max-height: 80vh;
}

.notification-popup-setting .MuiPaper-root.MuiPopover-paper {
  margin-right: 20px;
  left: auto !important;
  right: 0;
}

.request_popover_content_list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px !important;
}

.req_popup_btn {
  display: flex !important;
  justify-content: flex-start !important;
  color: #2d3748 !important;
  text-transform: lowercase !important;
  padding: 10px 18px !important;
}

.req_popup_btn:hover {
  /* cursor: auto !important; */
  background-color: #efe5fd !important;
}

.request_popover_content_list .MuiListItemIcon-root {
  min-width: unset !important;
  margin-right: 12px;
}

/* Responsive Menu Style */
.responsiveMenu_wrapper {
  width: 320px;
  background-color: #6e3fc6;
  height: 100%;
}

.respo_menu_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 12px;
}

.respoMenu_list .MuiListItemButton-root {
  position: relative;
  padding: 22px 24px !important;
  /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4); */
}

.respoMenu_list .MuiListItemButton-root::before {
  position: absolute;
  left: 0;
  top: -1px;
  right: 0;
  height: 1px;
  content: '';
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.12);
  -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.respoMenu_accordian {
  background-color: transparent !important;
  padding: 10px 8px !important;
  background-color: #3a2364a1;
}

.respoMenu_req_btn {
  display: flex !important;
  justify-content: flex-start !important;
  color: #fff !important;
  text-transform: lowercase !important;
}

@media screen and (min-width: 1400px) {
}

@media screen and (max-width: 1399.99px) {
}

@media screen and (max-width: 1199.99px) {
}

@media screen and (max-width: 991.99px) {
  .responsive_btn_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .responsive_menu_btn {
    min-width: unset !important;
    height: 100%;
    color: #f7f8f9 !important;
  }

  .nav_left {
    justify-content: space-between;
    margin-bottom: 4px;
  }

  .nav_menu {
    flex: 1;
    display: flex;
  }

  .nav_menu_center {
    flex: 1;
  }

  .nav_menu_right {
    display: none;
  }

  .nav_menu_center > ul > li > a {
    padding: 0 12px;
  }

  .main_nav_config_btn {
    height: 100%;
    min-width: unset !important;
    padding: 8px 12px !important;
  }
}

@media screen and (max-width: 767.99px) {
  .navbar_wrapper {
    padding: 8px 30px;
  }
  .logo_container svg {
    width: 100px;
  }
  .nav_menu_center {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 575.99px) {
  .navbar_wrapper {
    padding: 8px 12px;
  }
}

@media screen and (max-width: 450px) {
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 319.99px) {
}
