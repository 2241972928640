/* Profile Settings */

.location_popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(216, 216, 216, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 3000;
}
.location_popup_overlay.show {
  display: flex;
}
.location_box {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  height: 500px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 30px rgba(51, 51, 51, 0.5);
}
.location_box_map_view {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 300px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px rgba(51, 51, 51, 0.5);
}
.location_map {
  width: 100%;
  height: 100%;
}
.popup_close_btn {
  position: absolute !important;
  right: 15px;
  top: -45px;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  min-width: 0 !important;
}

/* Employee Page Style */

.employee_card_wrapper {
  padding: 4rem 0;
}

/* Business address style */
.dashboard_card {
  position: relative !important;
  background: #ffffff;
  border: 1px solid #edf4ff !important;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 10px !important;
}
.business_card_delete_btn {
  position: absolute !important;
  top: 1.5rem;
  right: 6.5rem;
  z-index: 10 !important;
}
.business_card_dirrection_btn {
  position: absolute !important;
  top: 1.5rem;
  right: 4rem;
  z-index: 10 !important;
}
.business_card_primary_btn {
  position: absolute !important;
  top: 1.5rem;
  right: 9rem;
  z-index: 10 !important;
}
.business_card_edit_btn {
  position: absolute !important;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 10 !important;
}
.business_address_selector {
  width: 200px !important;
}

.trust_setting_list li {
  padding-left: 0;
  display: flex;
  justify-content: space-between;
}

.invite_popup_wrapper .MuiPaper-root.MuiDialog-paper {
  width: 450px !important;
  min-height: unset !important;
  position: relative;
  overflow: hidden;
}
.invite_popup_header {
  position: absolute;
  top: 0;
  left: 0;
  height: fit-content;
  width: 100%;
  padding: 20px 24px 0px 24px;
  background-color: #ffffff;
  z-index: 1001;
}
.invite_popup_content {
  margin-top: 136px;
  height: auto;
  max-height: 600px;
  padding: 0 20px 10px 24px !important;
  overflow-y: auto;
}
.invite_popup_content.scrolling {
  box-shadow: inset 0 14px 10px -10px rgb(60 64 67 / 28%) !important;
}
.invite_popup_content.scrolling::before {
  z-index: 1;
  content: '';
  width: 100%;
  height: 14px;
  position: absolute;
  box-shadow: inset 0 14px 10px -10px rgb(60 64 67 / 28%) !important;
}
.invite_popup_content::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.invite_popup_content::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
  background-color: #6e3fc6 !important;
}
.invite_popup_content::-webkit-scrollbar-track {
  background-color: rgb(241, 243, 244) !important;
}

.help_popup_wrapper .MuiPaper-root.MuiDialog-paper {
  width: 350px !important;
  min-height: unset !important;
  padding: 20px 24px !important;
}
.day_assign_label {
  display: flex;
  align-items: center !important;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 5px;
  background-color: #e0ccfc;
  margin: 0 !important;
  color: #6200ee !important;
  transition: all 0.3s ease !important;
}
.day_assign_label .MuiFormControlLabel-label {
  font-size: 20px !important;
  font-weight: 600 !important;
}
.day_assign_label.checked {
  background-color: #6200ee;
  color: #e0ccfc !important;
}

.status_info {
  padding: 4px 14px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: #00cf73;
  border-radius: 6px;
}
.status_info.accept {
  background-color: #00cf73;
}

.status_info.reject {
  background-color: #fd4a4c;
}
.status_info.pending {
  background-color: #ffcb45;
}
.status_info.cancelled {
  background-color: #808080;
}
