.linking_popup_wrapper .MuiPaper-root.MuiDialog-paper {
  width: 400px !important;
  padding: 20px 24px !important;
  max-height: 700px !important;
  overflow-y: auto;
}
.linking_popup_searchbox {
  padding: 6px 10px !important;
  border: 1px solid #97a8be !important;
  border-radius: 10px !important;
}
.event_search_box {
  padding: 6px 10px !important;
  border: 1px solid #97a8be !important;
  border-radius: 5px !important;
  margin-bottom: 18px;
}

.linking_popup_searchbox.searchbox_with_searchby {
  padding: 0 0 0 10px !important;
  overflow: hidden !important;
}
.MuiPaper-root {
  color: unset !important;
  /* box-shadow: none !important; */
}
.linkList_avatar_container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-width: none !important;
}
.linkList_avatar_container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.linking_popup_wrapper .MuiButtonBase-root.MuiListItem-root:hover {
  background-color: transparent !important;
}
.linkList {
  max-height: 500px !important;
  overflow-y: auto;
}
.linkList::-webkit-scrollbar {
  display: none !important;
}

.request_popup_wrapper .MuiPaper-root.MuiDialog-paper {
  max-width: 450px;
  width: 100%;
  padding: 20px 24px !important;
  min-height: unset !important;
}
.request_popup_wrapper.linked_list_popup .MuiPaper-root.MuiDialog-paper {
  width: 450px !important;
  padding: 20px 0 0 !important;
  min-height: unset !important;
}

.app_user_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0 !important;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1) !important;
}
.app_user_avatar img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

/* App Card Component Style */

.app_card {
  width: 100% !important;
  background: #ffffff;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}
.app_card_trust_setting {
  width: 100% !important;
  background: #efe5fd !important;
  box-shadow: 1px 0px 4px #efe5fd, 0px 1px 2px #efe5fd, 0px 1px 3px #efe5fd !important;
  border-radius: 8px !important;
}
.app_card_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
}
.app_card_footer {
  border-top: 1px solid #ccd4df !important;
  padding: 12px 1rem !important;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50px; */
}

.MuiButtonBase-root.MuiCardActionArea-root.app_card_footer:hover {
  background-color: #fff !important;
}
/* User Image Placeholder */

.userImgPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  color: #ffffff !important;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out !important;
  flex-shrink: 0 !important;
}

.info_window_chat_btn {
  width: 20px !important;
  height: 20px !important;
  min-width: unset !important;
  margin-left: 30px !important;
}

/* Phone Intput with Edit button style */

.phone_input_with_edit_container {
  position: relative;
}
.phoneinput_edit_btn_container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}

/* Coming soon Style */

.coming_soon_bg {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  background-image: url('../../../assets/images/coming_soon_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

/* Location Popup Search Box Style */

.location_popup_searchbox_container {
  position: absolute;
  top: 14px;
  left: 1rem;
  z-index: 1;
}

/* Event Banner Style */
.event_image_slider_container {
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
}

.event_image_slider_container .slick-slider,
.event_image_slider_container .slick-slider .slick-list,
.event_image_slider_container .slick-slider .slick-list .slick-track,
.event_image_slider_container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide {
  height: 100% !important;
}
.event_image_slider_container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div {
  height: 100%;
  width: 100%;
}
.event_image_slider_container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  div
  .slide_image_wrapper {
  width: 100%;
  height: 100%;
}
.event_image_slider_container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  .slide_image_wrapper
  img,
.event_image_slider_container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  .slide_image_wrapper
  video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.event_creator_img_container {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.event_creator_img_container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.icon_squarebox {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
}

.app_popup .MuiPaper-root.MuiDialog-paper {
  width: 450px !important;
  min-height: 400px !important;
  max-height: 650px !important;
}

.biomatric_popup .MuiPaper-root.MuiDialog-paper {
  width: 450px !important;
  max-height: 650px !important;
}

.app_popup .MuiPaper-root.MuiDialog-paper {
  width: 450px !important;
  max-height: 650px !important;
}

.selfiverfication_popup .MuiPaper-root.MuiDialog-paper {
  width: 370px !important;
  max-height: 650px !important;
}

/* Infinite Scroll Modification */

.infinite-scroll-component {
  overflow: hidden !important;
}

.page-404-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.page-404-wrapper .page_404_btn {
  position: absolute;
  bottom: 10%;
}
@media screen and (max-width: 575.99px) {
  .MuiDialog-root .MuiPaper-root.MuiDialog-paper {
    margin: 12px;
  }
}
