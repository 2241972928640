.home_wrapper {
  position: relative !important;
  display: flex;
  align-items: flex-start !important;
  height: 100%;
  width: 100vw;
  overflow: hidden !important;
}

/* Map Category Style */
.map_category_wrapper {
  display: flex;
  position: relative;
  padding: 12px;
  height: 74px;
  top: 0;
  left: 420px;
  z-index: 100;
}

.not-btn {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content !important;
  margin-right: 30px;
}

.not-btn svg path {
  fill: #000;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.px-2.w-auto.not-btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  margin-right: 10px;
  /* padding-left: 5px; */
  margin-left: -20px;
}

/* Google Map Style */
.home_map_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.home_map_options_wrapper {
  display: flex;
  align-items: center;
}

.home_map_options_container {
  position: relative !important;
  max-width: 800px !important;
  width: 50vw !important;
  width: auto;
  overflow-x: auto !important;
  padding: 5px;
  height: 100%;
  /* background-color: #ffcb45; */
}

.home_map_options_container::-webkit-scrollbar {
  display: none;
}

.home_map_btn {
  padding: 8px 1rem !important;
  line-height: 1.5 !important;
  display: flex !important;
  align-items: center !important;
  background: #ffffff !important;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%) !important;
  border-radius: 30px !important;
  color: #626b76 !important;
  text-transform: lowercase !important;
  white-space: nowrap !important;
  width: fit-content !important;
  min-width: unset !important;
  min-height: unset !important;
  height: 40px !important;
  margin-right: 1rem !important;
}

.home_map_btn.mapActive {
  background-color: #6200ee !important;
  color: #fff !important;
}

.home_map_btn.mapActive .map_cat_btn_text {
  color: #fff !important;
}

.map_cat_btn_text {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.25rem;
  color: rgb(60, 64, 67);
}

.more_cat_map_btn_left {
  position: absolute !important;
  left: -15px !important;
  top: 50% !important;
  border-radius: 50% !important;
  padding: 5px !important;
  height: 30px !important;
  width: 30px !important;
  z-index: 15 !important;
  transform: translateY(-50%) !important;
  box-shadow: 0px 3px 10px #626b76 !important;
  display: none !important;
}

.more_cat_map_btn_right {
  position: absolute !important;
  top: 50% !important;
  right: -15px !important;
  border-radius: 50% !important;
  padding: 5px !important;
  height: 30px !important;
  width: 30px !important;
  transform: translateY(-50%) !important;
  box-shadow: 0px 3px 10px #626b76 !important;
  z-index: 15 !important;
  display: none !important;
}

.more_cat_map_btn_left.active {
  display: flex !important;
}

.more_cat_map_btn_right.active {
  display: flex !important;
}

/* Map SideBox Style */

.map_side_box {
  position: relative;
  top: 76px;
  left: 0;
  background: #ffffff;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 100;
  max-width: 480px;
  width: calc(100% - 24px);
  margin: 12px;
  height: 700px;
  max-height: calc(100vh - 100px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.map_side_box_header {
  padding: 0 1rem 12px;
}

.map_side_box.driving_mode_box {
  position: relative;
  top: 76px;
  left: 1rem;
  background: #ffffff;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 100;
  min-width: 400px;
  height: 120px;
  overflow: hidden;
  padding: 1rem;
  width: fit-content;
}

.map_side_box.live_location_box {
  position: relative;
  top: 76px;
  left: 1rem;
  background: rgb(110, 63, 198);
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 100;
  min-width: 450px;
  height: 67px;
  overflow: hidden;
  padding: 1rem;
  width: fit-content;
}

.map_sidebox_container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.map_sidebox_container.scrolling {
  box-shadow: inset 0 14px 10px -10px rgb(60 64 67 / 28%) !important;
}

.map_sidebox_container.scrolling::before {
  z-index: 1;
  content: '';
  width: 100%;
  height: 14px;
  position: absolute;
  box-shadow: inset 0 14px 10px -10px rgb(60 64 67 / 28%) !important;
}

.map_sidebox_container::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.map_sidebox_container::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
  background-color: #70757a !important;
}

.map_sidebox_container::-webkit-scrollbar-track {
  background-color: rgb(241, 243, 244) !important;
}

.map_side_box_content {
  width: 100%;
  height: 100%;
}

.map_side_box_list {
  padding: 1rem 1rem !important;
}

.map_sidebox_listItem {
  padding: 12px 0 12px 0 !important;
}

.map_side_box_list .map_sidebox_listItem:first-child {
  border-bottom: 1px solid #97a8be !important;
}

.map_side_box_list .map_sidebox_listItem:not(:last-child) {
  border-bottom: 1px solid #97a8be !important;
}

.map_side_box_footer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding-bottom: 1rem;
  background-color: #fff;
}

/* Track/Trace Box Style */

.tab_rounded_box {
  background: #f3f5f9;
  border-radius: 25px;
  width: 100%;
  margin-top: 1.5rem;
}

.track_req_list {
  padding: 0 !important;
}

.track_req_list_item {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100%;
  padding: 12px 0;
}

.status_chip_info {
  padding: 8px 14px !important;
  background: #ffcb45 !important;
  border-radius: 8px !important;
  color: #fff !important;
}

/* Map Filter Box Style */

.map_filter_box_popup .MuiPaper-root.MuiDialog-paper {
  width: 450px !important;
  min-height: unset !important;
  height: auto !important;
}

/* home page search btn Css */

.search_this_area_btn_container {
  position: absolute !important;
  left: 0 !important;
  top: 20% !important;
  height: 60px !important;
  width: 100vw !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: transparent !important;
  z-index: 100 !important;
  box-shadow: none !important;
}

.search_this_area_btn {
  background: #fff !important;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.3) !important;
  color: rgb(60, 64, 67) !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin: 8px auto 0 auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  border-radius: 21px !important;
  min-width: unset !important;
  width: 150px !important;
  height: 40px !important;
}

/*----------------*/

.new_infowindow_container {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
}

.new_infowindow_card {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e7f0ff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: start;
  grid-gap: 12px;
  gap: 12px;
}

.cand-view-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-list-item {
  background-color: #f3f3f3;
  border-radius: 8px;
}
.flex-1 {
  flex: 1 1 0;
}
.verify_auth_container {
  padding-top: 15rem;
}
/*----------------*/
@media screen and (min-width: 1400px) {
}

@media screen and (max-width: 1399.99px) {
}

@media screen and (max-width: 1199.99px) {
}

@media screen and (max-width: 991.99px) {
  .home_map_options_container {
    width: 47vw !important;
  }
  .verify_auth_container {
    padding-top: 10rem;
  }
}

@media screen and (max-width: 767.99px) {
  .map_category_wrapper {
    display: flex;
    position: relative;
    padding: 1rem;
    justify-content: flex-start;
    height: 74px;
    top: 62px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    width: 100%;
  }

  .home_map_options_container {
    width: 96vw !important;
  }
}

@media screen and (max-width: 575.99px) {
  .home_map_options_container {
    width: 93vw !important;
  }
}

@media screen and (max-width: 450px) {
  .home_map_options_container {
    width: 92vw !important;
  }
}

@media screen and (max-width: 400px) {
  .home_map_options_container {
    width: 90vw !important;
  }
}

@media screen and (max-width: 375px) {
  .social-text {
    display: none;
  }
}
