/* Never discard all these google classes */

.gm-style-iw.gm-style-iw-c {
  background: #ffffff !important;
  box-shadow: 0px 0.461734px 0.923467px rgba(0, 0, 0, 0.06),
    0px 0.461734px 1.3852px rgba(0, 0, 0, 0.1) !important;
  padding: 0 !important;
  top: -30px !important;
}
.gm-style-iw-d {
  padding: 0 !important;
  overflow: hidden !important;
}
/* .gm-style-iw > button {
  display: none !important;
} */
.gm-style-iw-tc {
  /* display: none !important; */
  top: -30px !important;
}
.gm-ui-hover-effect {
  visibility: hidden !important;
}
.info_window_container {
  display: flex;
  align-items: stretch !important;
  height: 100% !important;
  width: 100% !important;
  min-height: 110px !important;
  min-width: 280px !important;
}
.info_window_distance_container {
  width: 30%;
  background-color: #6200ee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info_window_distance_container.person {
  background-color: #6200ee;
}
.info_window_distance_container.business {
  background-color: #00d1ee;
}
.info_window_distance_container.group {
  background-color: #006699;
}
.info_window_distance_container.employee {
  background-color: #007cee;
}
.info_window_distance_container.google {
  background-color: #34a853;
}
.info_window_info_container {
  position: relative !important;
  width: 70%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.info_window_info_container li {
  padding: 4px 12px !important;
}
.live_status_badge {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.live_status_badge.online {
  background-color: #00cf73;
}
.live_status_badge.offline {
  background-color: #fd4a4c;
}

#convasContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 20%;
  left: 20%;
  z-index: 100000 !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 auto;
  background-color: red !important;
  /* visibility: hidden !important; */
}
#mapMarkerCanvas {
  background-color: transparent !important;
}

/* new info_window_container */

.new_infowindow_card {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e7f0ff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: start;
  gap: 12px;
}

.new_infowindow_container {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
}
.new_infowindow_container img,
.new_infowindow_container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 575px) {
  .new_infowindow_card {
    flex-direction: column;
  }
  .new_infowindow_container {
    height: 80px;
    width: 80px;
  }
}
