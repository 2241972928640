.master_wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.main_wrapper {
  position: relative;
  display: flex;
  height: 100%;
}
.main_content_wrapper {
  position: relative;
  background-image: url('../../../assets/images/auth_bg_right_wave.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: calc(100vh - 60px);
  width: 100%;
  padding: 1.5rem 40px 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 60px;
}

.map_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-image: none;
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.map_sidebar_layout_wrapper {
  max-width: 410px;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
}

@media screen and (min-width: 1400px) {
}
@media screen and (max-width: 1399.99px) {
}
@media screen and (max-width: 1199.99px) {
}
@media screen and (max-width: 991.99px) {
}
@media screen and (max-width: 767.99px) {
  .main_content_wrapper {
    padding: 1rem 30px 5rem;
  }
}
@media screen and (max-width: 767px) {
}
@media screen and (max-width: 575.99px) {
}
@media screen and (max-width: 450px) {
  .main_content_wrapper {
    padding: 2rem 1.2rem 5rem;
  }
}
@media screen and (max-width: 475px) {
  .map_sidebar_layout_wrapper {
    max-width: 300px;
  }
}
@media screen and (max-width: 319.99px) {
}
