.img_preview_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #97a8be;
  border-radius: 8px;
}

.img_preview_container_cemera {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #97a8be;
  border-radius: 8px;
  height: 300px;
  width: 500px;
}

.img_preview_container_picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #97a8be;
  border-radius: 8px;
  height: 250px;
  width: 350px;
}

.img_preview_circle {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  /* margin: 0 auto; */
  flex-shrink: 0;
}
.img_preview_wrapper {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex-shrink: 0;
}
.img_preview_wrapper img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.img_preview_circle img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

/* Event media from control style */

.upload_event_media_label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.event_media_preview_container {
  border: 1px solid #97a8be;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
  max-height: 400px;
  overflow-y: scroll;
  min-height: 300px;
  padding: 12px 0 0 12px;
  background-color: #ffffff;
}
.event_media_preview_container.video {
  padding: 0;
  height: 300px;
  width: 100%;
  overflow: hidden;
}
.event_media_preview_container.video video {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
  object-position: center;
}
.event_media_preview_container .text_limit_150 {
  padding: 0 10px;
  width: 100% !important;
}
