@media screen and (min-width: 1400px) {
}

@media screen and (max-width: 1399.99px) {
  .auth_left_container
    .auth_slider_wrapper
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .slide_item
    .top_content {
    height: 350px;
  }
}

@media screen and (max-width: 1199.99px) {
  .socialpost_slider_wrapper {
    height: 300px !important;
  }

  .auth_left_container
    .auth_slider_wrapper
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .slide_item
    .top_content {
    height: 250px;
  }
  .auth_left_container
    .auth_slider_wrapper
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .slide_item
    .top_content
    .image_wrapper {
    max-width: 200px;
    margin: auto;
  }
  .auth_left_container
    .auth_slider_wrapper
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .slide_item
    .bottom_content
    h3 {
    font-size: 28px !important;
  }

  .auth_left_container
    .auth_slider_wrapper
    .slick-slider
    .slick-list
    .slick-track
    .slick-slide
    .slide_item
    .bottom_content
    p {
    font-size: 14px !important;
  }
  .auth_left_container .auth_slider_wrapper .custom_auth_slider_nav {
    position: absolute;
    top: 15px;
    left: 0px;
    z-index: 1;
    width: 100%;
    padding: 0px 10px !important;
  }

  img.contact_us_image {
    width: 330px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .social_avatar {
    left: 20px;
  }
}

@media screen and (max-width: 767.99px) {
  .social_wrapper .social_header .social_avatar {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100%;
    left: 16px;
    border: 75px solid #fff;
    transform: translateY(-50%);
  }

  .social_wrapper .social_header {
    min-height: 250px;
    height: 250px;
  }

  .social_wrapper .social_header .social_avatar .app_user_avatar {
    width: 140px !important;
    height: 140px !important;
  }

  .event_wrapper .event_content_wrapper .event_new_card_wrapper {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 599px) {
  .social_wrapper .social_header .social_avatar {
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .social_wrapper .social_header .social_followers_details {
    display: none;
  }
  .social_wrapper .social_config {
    padding: 0px 18px 24px;
    margin-top: 40px;
  }
}
@media screen and (max-width: 575.99px) {
  .social_wrapper .social_header .social_avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100%;
    border: 65px solid #fff;
  }

  .social_wrapper .social_header {
    min-height: 200px;
    height: 200px;
  }

  .social_wrapper .social_header .social_avatar .app_user_avatar {
    width: 120px !important;
    height: 120px !important;
  }

  .social_page_wrapper .social_post_wrapper .socialpost_slider_wrapper {
    height: 300px;
    overflow: auto;
  }

  .event_new_card_wrapper .new_event_card_image_wrraper {
    height: 140px;
  }

  .event_image_slider_container {
    height: 250px;
  }

  .contact_us_wrapper .contact_us_image_div img.contact_us_image {
    width: 250px;
    height: auto;
  }
}

@media screen and (max-width: 450px) {
  .event_new_card_wrapper .new_event_card_image_wrraper {
    height: 120px;
  }

  .create_event_wrapper .img_video_wrapper {
    height: 150px;
  }

  .create_event_wrapper .img_video_wrapper span.remove_icon {
    width: 20px;
    height: 20px;
    top: 8px;
    right: 8px;
  }

  .socialpost_slider_wrapper {
    height: 220px !important;
  }
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 319.99px) {
}
