.business_profile_wrapper {
  width: 100vw;
  height: calc(100vh - 60px);
  margin-top: 60px;
  padding: 0 40px;
  overflow-y: auto;
  overflow-x: hidden;
}
.business_profile_wrapper .business_profile_header {
  min-height: 300px;
  height: 300px;
  width: 100%;
  position: relative;
}
.business_profile_wrapper .business_profile_header img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.business_profile_wrapper .business_profile_header .business_profile_avatar {
  width: 163px;
  height: 163px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100%;
  left: 50px;
  border: 2.23704px solid #6a00ff;
  transform: translateY(-50%);
}
.business_profile_wrapper .business_profile_header .business_followers_details {
  max-width: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  right: 50px;
  bottom: -28px;
  gap: 8px;
}
.business_profile_wrapper
  .business_profile_header
  .business_followers_details
  .text-content {
  flex: 1;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #e7f0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6px 8px;
  box-shadow: 0px 2px 6px rgba(69, 79, 90, 0.08);
}
.business_profile_wrapper
  .business_profile_header
  .business_followers_details
  .text-content
  p {
  margin-bottom: 0px;
  margin-top: 2px;
  color: #97a8be;
}

.business_followers_details .MuiButton-root {
  flex: 1 !important;
}

.business_profile_wrapper .business_config {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 50px 1rem;
}
.business_profile_wrapper .business_config .business_btn_row {
  display: flex;
  justify-content: end;
  padding: 2rem 0;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .business_profile_wrapper
    .business_profile_header
    .business_followers_details {
    position: relative;
    bottom: unset;
    right: unset;
    margin: 10px auto;
  }
  .business_profile_wrapper .business_profile_header .business_profile_avatar {
    position: relative;
    left: unset;
    top: unset;
    transform: translateY(0);
    margin: -90px auto 0;
  }
  .business_profile_wrapper .business_profile_header {
    height: fit-content;
  }
  .bg-profile {
    max-height: 350px;
  }
  .business_profile_wrapper .business_config .business_btn_row {
    padding: 0 0 12px;
    justify-content: center;
  }
  .business_profile_wrapper .business_config {
    padding: 0 16px 1rem;
  }
  .business_config .business_btn_row button {
    min-width: auto;
  }
  .business_profile_wrapper {
    padding: 0 12px;
  }
}

@media screen and (max-width: 599px) {
}
