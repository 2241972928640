.auth_wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.auth_left_container {
  height: 100%;
  width: 100%;
  background-image: url('../../../assets/images/auth_bg_left.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.auth_left_container .auth_slider_wrapper {
  width: 50%;
  margin: auto;
  height: 100vh;
  position: relative;
}
.auth_left_container .auth_slider_wrapper .custom_auth_slider_nav {
  position: absolute;
  top: 35px;
  left: 0px;
  z-index: 1;
  width: 100%;
  padding: 0px 30px;
}
.auth_left_container .auth_slider_wrapper .custom_auth_slider_nav button {
  border: none;
  background-color: transparent;
}
.auth_left_container .auth_slider_wrapper .custom_auth_slider_nav button {
  color: #6200ee;
  font-size: 14px;
  text-transform: lowercase;
  font-weight: 500;
}

.auth_left_container .auth_slider_wrapper .slick-slider {
  width: 100%;
  height: auto;
}

.auth_left_container .auth_slider_wrapper .slick-slider .slick-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 10px;
  bottom: -60px;
}

.auth_left_container .auth_slider_wrapper .slick-slider .slick-dots li {
  width: 12px;
  height: 6px;
  border-radius: 12px;
  background-color: #a7b5cb;
  display: block;
  padding: 0px;
  margin: 0px;
  transition: all 0.5s ease;
}

.auth_left_container
  .auth_slider_wrapper
  .slick-slider
  .slick-dots
  li
  button::before {
  display: none;
}

.auth_left_container
  .auth_slider_wrapper
  .slick-slider
  .slick-dots
  li.slick-active {
  width: 28px;
  background-color: #00f3ff;
}

.auth_left_container .auth_slider_wrapper .slick-slider .slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.auth_left_container .auth_slider_wrapper .slick-slider .slick-list,
.auth_left_container
  .auth_slider_wrapper
  .slick-slider
  .slick-list
  .slick-track,
.auth_left_container
  .auth_slider_wrapper
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide,
.auth_left_container
  .auth_slider_wrapper
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div {
  width: 100%;
  height: 100%;
}

.auth_left_container
  .auth_slider_wrapper
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div
  .slide_item {
  width: 100%;
  height: 100%;
}

.auth_left_container
  .auth_slider_wrapper
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  .slide_item
  .top_content {
  height: 500px;
  background-color: #f2e9ff;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.auth_left_container
  .auth_slider_wrapper
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  .slide_item
  .top_content:first-child {
  background-color: #ecdeff;
}

.auth_left_container
  .auth_slider_wrapper
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  .slide_item
  .bottom_content {
  margin-top: 60px;
}

.auth_left_container
  .auth_slider_wrapper
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  .slide_item
  .bottom_content
  h3 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 1;
  color: #ffffff;
}

.auth_left_container
  .auth_slider_wrapper
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  .slide_item
  .bottom_content
  p {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.auth_welcome_box {
  width: 264px;
  height: 272px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome_icon {
  margin-bottom: 2rem;
}

.welcome_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth_right_container {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url('../../../assets/images/auth_bg_right_wave.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.auth_right_container a {
  text-decoration: none !important;
}
.goback_btn_container {
  position: absolute;
  top: 5%;
  left: 5%;
}

.auth_container {
  margin: 0 auto;
}

.auth_box {
  max-width: 327px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0rem auto 0;
  padding-bottom: 3rem;
}

.auth_box_header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (min-width: 1400px) {
  .auth_box {
    max-width: 380px;
    min-width: 360px;
  }
}

@media screen and (max-width: 1399.99px) {
  .auth_box {
    min-width: 327px;
  }
}

@media screen and (max-width: 1199.99px) {
}

@media screen and (max-width: 991.99px) {
  .auth_right_container {
    padding-top: 5rem;
  }

  .auth_container {
    width: 60%;
  }

  .auth_box_header {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 767.99px) {
}

@media screen and (max-width: 575.99px) {
  .auth_container {
    width: 80%;
  }

  .auth_box {
    min-width: 320px;
  }
}

@media screen and (max-width: 400px) {
  .auth_box {
    min-width: unset;
  }
}

@media screen and (max-width: 319.99px) {
}

/* Based on Height */

@media screen and (min-height: 700px) and (min-width: 991px) {
  .auth_right_container {
    padding: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding-top: 10rem;
  }
}

@media screen and (max-height: 699.99px) {
  .auth_right_container {
    padding: 5rem 0 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}
