@import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import 'react-phone-input-2/lib/material.css';
@import './commonStyle/typography.css';
@import './commonStyle/buttons.css';
@import './commonStyle/theme.css';
@import './commonStyle/customComponent.css';
@import './commonStyle/muiCustomization.css';
@import './commonStyle/formControls.css';
@import './commonStyle/component.css';
@import './authStyle/auth.css';
@import './mainStyle/top-nav.css';
@import './mainStyle/left-sidebar.css';
@import './mainStyle/master-layout.css';
@import './mainStyle/dashboard.css';
@import './mainStyle/home.css';
@import './mainStyle/chat.css';
@import './mainStyle/social.css';
@import './mainStyle/bussinessProfile.css';
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';
@import './mainStyle/events.css';
@import '~slick-carousel/slick/slick.css';
@import '../styles/responsiveBreakPointBoilerPlate.css';
@import '~slick-carousel/slick/slick-theme.css';
html,
body {
  font-family: 'Google Sans', Roboto, Arial, sans-serif !important;
  scroll-behavior: smooth;
}
input {
  font-family: 'Google Sans', Roboto, Arial, sans-serif !important;
}
::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 5px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: inherit;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  /* border: 4px solid #fff; */
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

.custom_scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 5px;
}
.custom_scrollbar::-webkit-scrollbar-track {
  background-color: inherit;
}
.custom_scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}
