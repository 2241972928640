.app_blank_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  background-color: transparent;
}
.app_btn {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  line-height: 1.5 !important;
  display: flex;
  align-items: center;
  max-height: 48px !important;
  width: fit-content !important;
}
.app_btn_lg {
  width: 100%;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  line-height: 1.5 !important;
  display: flex;
  align-items: center;
  max-height: 48px !important;
}
.app_btn_lg.link {
  width: 100%;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  line-height: 1.5 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  max-height: 48px !important;
  text-decoration: none !important;
  border-radius: 8px !important;
}
.app_shodow_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.linkList_btn {
  padding: 8px 14px !important;
  border-radius: 6px !important;
  width: 80px !important;
}
.user_list_card_btn_link {
  padding: 8px 14px !important;
  border-radius: 6px !important;
  width: 85px !important;
}
.app_black_btn {
  background: #2d3748 !important;
  border-radius: 8px !important;
  min-width: unset !important;
}
.app_null_btn {
  background: transparent !important;
  min-width: unset !important;
  padding: 0 !important;
}
.app_row_btn {
  border: 1px solid #6200ee;
  border-radius: 8px !important;
  padding: 8px 14px !important;
  min-width: unset !important;
}
.app_sidebar_card_circle_icon_btn {
  position: relative !important;
  z-index: 100 !important;
  border: 1px solid #6200ee !important;
  border-radius: 50% !important;
  background: transparent !important;
  min-width: unset !important;
  padding: 0 !important;
  flex-shrink: 0 !important;
  width: 30px !important;
  height: 30px !important;
}
.app_sidebar_card_circle_icon_btn:hover {
  background-color: #e0ccfc !important ;
}
.app_sidebar_card_circle_icon_btn_follow {
  position: relative !important;
  z-index: 100 !important;
  border: 1px solid #00cf73 !important;
  border-radius: 50% !important;
  background: transparent !important;
  min-width: unset !important;
  padding: 0 !important;
  flex-shrink: 0 !important;
  width: 30px !important;
  height: 30px !important;
  background-color: #00cf73 !important;
}
.app_sidebar_card_circle_icon_btn_follow:hover {
  background-color: #87e2b9 !important ;
}
.app_close_float_btn_topRight {
  position: absolute !important;
  top: 4px !important;
  right: 4px !important;
  min-width: unset !important;
  padding: 8px !important;
  border-radius: 50% !important;
  z-index: 100 !important;
  background-color: #e0ccfc !important;
  height: 24px;
  width: 24px;
}
.MuiButton-contained.theme_button {
  padding: 6px 16px;
  min-width: 64px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  border-radius: 4px;
  color: #ffffff;
  background-color: #6200ee !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  text-transform: lowercase !important;
}
.MuiButton-outlined.theme_button {
  padding: 5px 16px;
  min-width: 64px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  border-radius: 4px;
  color: #6200ee;
  background-color: transparent !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  text-transform: lowercase !important;
}
.MuiButton-contained.theme_button_inactive {
  padding: 6px 16px;
  min-width: 64px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  background-color: #ffffff;
  border: 1px solid #97a8be;
  border-radius: 8px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  text-transform: lowercase !important;
}
.slick-slider {
  line-height: 1 !important;
}
.slick-slider .slick-arrow::before {
  color: #6e3fc6 !important;
}
.slick-slider .slick-slide {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
